import React, { useEffect, useState } from 'react';
import Navbar from '../../components/CustomNavbar';
import { GetAllUserFromDBApi } from "../../services/audioAlbum";
import './userSubscription.css';

const AllUser = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUserList = async () => {
            try {
                const response = await GetAllUserFromDBApi();
                // Reverse the order of users
                setUsers(response.data.reverse());
            } catch (err) {
                console.error(err);
            }
        };

        fetchUserList();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="subscription-table">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.user_name}</td>
                                <td>{user.email}</td>
                                <td>{user.contact || 'N/A'}</td>
                              
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllUser;