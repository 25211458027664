import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './VideoDetail.css';
import { getVideoById, getVideoUrlFromCloudFront } from '../services/apiCallServices';
import { getToken } from '../services/token';
import Navbar from '../components/CustomNavbar';

const VideoDetail = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const expireTime = 60;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();

    
        const videoData = await getVideoById(videoId, token);
        setVideo(videoData);


        const videoUrl = await getVideoUrlFromCloudFront(videoId, expireTime, token);
        setVideo((prevVideo) => ({ ...prevVideo, videoUrl }));
      } catch (error) {
        console.error('Error fetching video data:', error.message);
      }
    };

    fetchData();
  }, [videoId, expireTime]);

  if (!video) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <div className="video-detail-background">
        <div className="video-detail-container">
          <div>
            <video
              controls
              width="100%"
              height="100%"
              autoPlay
              controlsList="nodownload"
              style={{ objectFit: 'cover' }}
              src={video.videoUrl}
            ></video>
          </div>

          <div className="video-details">
            <h2 className="video-h2">{video.title}</h2>
            <p className="video-p">{video.description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoDetail;
