import React from 'react';
import './VideoDetail.css';
import Progress from '../components/Progress'

const VideoDetail = () => {

return ( 
  <>
  <Progress />
  </>
);
};
export default VideoDetail;
