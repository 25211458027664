import React, { useState } from 'react';
import CustomNavbar from "../../components/CustomNavbar";
import "../css/uploadalbum.css"
import Switch from "react-switch";
import { UploadAlbumAdmin } from '../../services/audioAlbum';
import { LinearProgress } from '@mui/material'; // Import Material-UI component
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UploadAlbum = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [thumbnailFile, setThumbnailFile] = useState(null); // State to hold the selected file
    const [price, setPrice] = useState('');
    const [albumStatus, setAlbumStatus] = useState(false);
    const [albumPremium, setAlbumPremium] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // State to hold the upload progress
    const [loading,setLoading]=useState(false);
    const navigate=useNavigate();
    const handleAlbumStatus = () => {
        setAlbumStatus(!albumStatus);
    }

    const handleAlbumPremium = () => {
        setAlbumPremium(!albumPremium);
    }
    const handleChangePrice = (e) => {
        const inputPrice = parseFloat(e.target.value);
        if (inputPrice >= 0) {
            setPrice(inputPrice);
        } else {
            toast.error("Price cannot be negative")
        }
    };
    
    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        if (title && description && thumbnailFile && price) {

            const formData = new FormData();
            formData.append("title", title);
            formData.append("description", description);
            formData.append("thumbnailUrl", thumbnailFile); // Append the file directly
            formData.append("price", price);

            if (albumPremium) {
                formData.append("albumType", "Premium");
                if(price<=0){
                    setLoading(false)
                    toast.error("price is required")
                    return;
                }
            } else {
                formData.append("albumType", "Free");
            }

            if (albumStatus) {
                formData.append("status", "Active");
            } else {
                formData.append("status", "Deactive");
            }

            try {
                const config = {
                    onUploadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(progress); // Update upload progress
                    }
                };
                
                const response = await UploadAlbumAdmin(formData, config);
                console.log(response)
                toast.success("Data Uploaded successfully")
                navigate("/albums")
                setTitle('');
                setDescription('');
                setThumbnailFile(null); 
                setPrice(0);
                setLoading(false)
            } catch (err) {
                setLoading(false)
                console.error(err);
                toast.error(err?.response?.data?.error);
            }
        } else {
            setLoading(false)
            toast.error('Please fill in all fields');
        }
    };

    return (
        <div>
            <CustomNavbar />
    
            <div className="upload-album-container">
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Title:</label>
                        <input type='text' name='title' value={title} onChange={(e) => setTitle(e.target.value)} required />
                    </div>
                    <div>
                        <label>Description:</label>
                        <input type='text' name='description' value={description} onChange={(e) => setDescription(e.target.value)} required />
                    </div>
                    <div>
                        <label>Thumbnail:</label>
                        <input type='file' name='thumbnailUrl' accept='image/*' onChange={(e) => setThumbnailFile(e.target.files[0])} required />
                    </div>
                    <div>
                        <label>Price:</label>
                        <input type='number' name='price' value={price} onChange={handleChangePrice} />
                    </div>
                    <div>
                        <p>Set Active</p>
                        <Switch onChange={handleAlbumStatus} checked={albumStatus} />
                    </div>
                    <div>
                        <p>Set Premium</p>
                        <Switch onChange={handleAlbumPremium} checked={albumPremium} />
                    </div>
                    {loading?<button >Loading</button>: <button type='submit'>Submit</button>}
                    {uploadProgress > 0 && <LinearProgress variant="determinate" value={uploadProgress} />} {/* Progress bar */}
                </form>
            </div>
        </div>
    );
};

export default UploadAlbum;
