import React, { useEffect, useState } from "react";
import "./albumrow.css";
import Switch from "react-switch";
import { UpdateAlbum } from "../../services/audioAlbum";
import { useNavigate } from "react-router-dom";
import DeleteAlbum from "../AlbumDetails/DeleteAlbum";
import { MdOutlineModeEditOutline } from "react-icons/md";
const AlbumRow = ({albumId,albumType,description,price,status,thumbnailUrl,title,showButton}) => {
    const [albumStatus,setAlbumStatus]=useState(false)
    const [albumPremium,setAlbumPremium]=useState(false)
    const navigate =useNavigate();
    useEffect(() => {
        setAlbumStatus(status === "Active");
        setAlbumPremium(albumType === "Premium")
    }, [status,albumType]);
    const shortTitle = title.substring(0, 20) + (title.length > 10 ? "..." : "");

    const shortDescription = description.substring(0, 20) + (description.length > 20 ? "..." : "");
    const handleAlbumStatus=(id)=>{
        const formData = new FormData();
        if(!albumStatus){
            formData.append('status', "Active");
            
        }else{
            formData.append('status', "Deactive");
        }
        UpdateAlbum(id,formData).then(res=>{
            if(res?.status===200){
                setAlbumStatus(!albumStatus)
            }
            
        }).catch(err=>{
            console.error(err)
        })
      
    }
    const handleAlbumPremium=(id)=>{
        const formData = new FormData();
        if(!albumPremium){
            formData.append('albumType', "Premium");
            
        }else{
            formData.append('albumType', "Free");
        }
        UpdateAlbum(id,formData).then(res=>{
            if(res?.status===200){
                setAlbumPremium(!albumPremium)
            }
        }).catch(err=>{
            console.error(err)
        })
        
    }
    // const handleAlbumDetails=(id)=>{
    //   navigate('/album-in-details', { state: { id: id } });
    // }
    const handleGoToEditAlbumAndAudio = (album)=>{
      navigate('/uploadaudio',{ state: { album:album} });
    }
  return (
    <div className="albumrow">
    
    
      <div className="album-thumbnail">
        {thumbnailUrl === "Thumbnail" ? <b>Thumbnail</b> : <img src={thumbnailUrl} alt="album thumbnail" />}
      </div>
      <div className="title">
        <b>{shortTitle}</b>
      </div>
      
      <div className="description">
        <b>{shortDescription}</b>
      </div>
      <div className="albumprice">
        <b>{price}₹</b>
      </div>
      <div className="albumType">
      {albumType==="Set Premium"?<b>Set Premium</b>: <Switch onChange={()=>handleAlbumPremium(albumId)} checked={albumPremium} />}
      </div>
      <div className="status">
        {status==="status"?<b>Status</b>: <Switch onChange={()=>handleAlbumStatus(albumId)} checked={albumStatus} />}
      </div>
      <div className="edit">
      {showButton==="No"? <b>EDIT</b>: <MdOutlineModeEditOutline onClick={()=>handleGoToEditAlbumAndAudio(albumId)} size="1.5rem"/>}
      </div>
      {/* <div className="edit">
      {showButton==="No"? <b>Details</b>: <IoIosInformationCircleOutline onClick={()=>handleAlbumDetails(albumId)} size="1.5rem"/>}
      </div> */}
      <div className="delete">
      {showButton==="No"? <b>Delete</b>: <DeleteAlbum  albumId={albumId}/>}
      </div>
    </div>
  );
};

export default AlbumRow;
