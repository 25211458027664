import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Videolist from './pages/Videolist';
import Dashboard from './components/Dashboard';
import Videosearch from './pages/Videosearch';
import Createvideo from './pages/Createvideo';
import VideoDetail from './pages/VideoDetail';
import Transaction from './pages/Transaction';
import Viewsub from './pages/Viewsub';
import Login from './pages/Login';
import Manage from './pages/Manage';
import Videoplan from './pages/Videoplan';
import Category from './pages/Category';
import Mcategory from './pages/Mcategory';
import UserWiseVideo from './pages/UserWiseVideo';
import CustomNavbar from './components/CustomNavbar';
import Progress from './components/Progress';
import 'react-toastify/dist/ReactToastify.css';
import Uploadaudio from './pages/Uploadaudio';
import AllAlbum from './pages/album/AllAlbum';
import UploadAlbum from './pages/album/uploadAlbum';
import AlbumDetails from './components/AlbumDetails/AlbumDetails';
import { ToastContainer } from 'react-toastify';
import UserSubscriptionList from './pages/subscriptionList/UserSubscriptionList';
import UserVideoPurchaseList from './pages/subscriptionList/UserVideoPurchaseList';
import AllUser from './pages/subscriptionList/AllUser';



function App() {
  const videoList = []; 

  return (
    <Router>
    <ToastContainer />
      <Routes>
      
        <Route path="/" element={<Login />} />
        <Route
          path="/Dashboard"
          element={
            localStorage.getItem('token') ? (
              <Dashboard />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {localStorage.getItem('token') ?
        <>
         <Route path="/Videolist" element={<Videolist />} />
         <Route path="/Videosearch" element={<Videosearch />} />  
        <Route path="/Createvideo" element={<Createvideo />} />
        <Route path="/Uploadaudio" element={<Uploadaudio />} />
         <Route path="/video/:videoId" element={<VideoDetail Videolist={videoList} />} />
        <Route path="/Transaction" element={<Transaction />} />
        <Route path="/Viewsub" element={<Viewsub />} />  
        <Route path="/Manage" element={<Manage />} />
        <Route path="/Videoplan" element={<Videoplan />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/Mcategory" element={<Mcategory />} />
        <Route path='/albums' element={<AllAlbum />} />
        <Route path='/upload-album' element={<UploadAlbum />} />
        <Route path='/album-in-details' element={<AlbumDetails />} />
    
        <Route path="/userwisevideo" element={<UserWiseVideo />} />
        <Route path="/customNavbar" element={<CustomNavbar />} />
        <Route path="/progress" element={<Progress />} />
        <Route path="/user-subscription-list" element={<UserSubscriptionList />} />
        <Route path="/user-video-album-list" element={<UserVideoPurchaseList />} />
        <Route path="/all-user-list" element={<AllUser />} />
        {/* <Route path="/usercount" element={<UserCount />} /> */}
        </>:
        <Route path='/*' element={<Login />} />
        }
      </Routes>
    </Router>
  );
}

export default App;