import React from 'react';
import './Logout.css'; 

const Logout = ({ showModal, closeModal, handleLogout }) => {
  return (
    <div className={`modal1 ${showModal ? 'show1' : ''}`}>
      <div className="modal-content1">
        <h4>Logout Confirmation</h4>
        <p>Are you sure you want to log out?</p>
        <div className="modal-buttons1">
          <button onClick={handleLogout}>Logout</button>
          <button onClick={closeModal}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Logout;