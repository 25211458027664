import React, { useState, useEffect } from 'react';
import './userwisevideo.css';
import { fetchUserVideoCount } from '../services/apiCallServices';
import Navbar from '../components/CustomNavbar';
import { FaArrowCircleUp } from 'react-icons/fa';


const UserVideoCount = () => {
  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchVideoCount = async () => {
    try {
      const data = await fetchUserVideoCount();
      console.log(data)
      setUserData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchVideoCount(); 

  }, []);

  const filteredUsers = userData.filter((user) => {
    const userNameString = String(user.user_name);
    const emailString = String(user.email);

    return (
      userNameString.toLowerCase().includes(searchTerm.toLowerCase()) || emailString.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.video_count.toString().includes(searchTerm)
    );
  });

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='user-video'>
      <Navbar />
      <div className="user-video-count-container">
        <input
          type="text"
          placeholder="Search by User Name/Email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className='userwisearch'
        />
        <table className="user-video-count-table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Video Count</th>
              <th>Album Count</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.user_name}>
                <td>{user.user_name}</td>
                <td>{user.email}</td>
                <td>{user.video_count}</td>
                <td>{user.audio_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className="scroll-to-top-count" onClick={handleScrollToTop}>
      <FaArrowCircleUp />
      </button>
    </div>
  );
};

export default UserVideoCount;
