import React, { useState } from 'react';
import { NavLink as ReactLink } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import Logout from './Logout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoHomeOutline } from "react-icons/io5";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './navbar.css';

const CustomNavbar = (args) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };
  return (
    <div className='txt-color1'>

      <Navbar expand="lg" fixed="top" className="navbar-transparent-custom1">
      <NavbarBrand tag={ReactLink} to="/dashboard"  className="logo-admin">
        <IoHomeOutline />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="custom-navbar1" navbar> {/* Use a custom class for alignment */}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Video Management
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag={ReactLink} to="/videolist">Video List</DropdownItem>
                {/* <DropdownItem tag={ReactLink} to="/videosearch">Manage Video By Id/Name</DropdownItem> */}
                <DropdownItem tag={ReactLink} to="/createvideo">Create Video</DropdownItem>
               
                {/* <DropdownItem tag={ReactLink} to="/charts">View Graph</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Audio Management
              </DropdownToggle>
              <DropdownMenu end>
              <DropdownItem tag={ReactLink} to="/albums">Album List</DropdownItem>
                <DropdownItem tag={ReactLink} to="/upload-album">Create Album</DropdownItem>
                {/* <DropdownItem tag={ReactLink} to="/uploadaudio">Create Audio</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                User Management
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag={ReactLink} to="/transaction">Transaction Details</DropdownItem>
                <DropdownItem tag={ReactLink} to="/userwisevideo">User View Count</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>


            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Subscription Plan Management
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag={ReactLink} to="/manage">Create And Manage Plan</DropdownItem>
                <DropdownItem tag={ReactLink} to="/videoplan">Video Plan Mapping</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}

           

            <BiLogOut className="logout-btn" onClick={openModal} title='logout'>
              Logout
            </BiLogOut>
            <Logout showModal={showModal} closeModal={closeModal} handleLogout={handleLogout} />


          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;

