import axios from "axios"
import { getToken } from "./token";

// export const BASE_URL ='https://apiadmin.drsunilkale.com'//dev Url
// export const BASE_URL ='https://apiadmin.drsunilkale.com'//local Urls
export const BASE_URL ='https://apiadmin.drsunilkale.com'//Ip

export const myAxios = axios.create({
    baseURL: BASE_URL,
})
export const privateAxios = axios.create({
    baseURL: BASE_URL,
})
privateAxios.interceptors.request.use(config=>{
    const token = getToken();
    if(token){
        config.headers.Authorization = `Bearer ${token}`
        return config;
    }
},error=>Promise.reject(error));