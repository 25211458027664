import React, { useEffect, useState } from "react";
import CustomNavbar from "../../components/CustomNavbar";
import "../css/allalbums.css";
import { GetAllAlbumSearchApi } from "../../services/audioAlbum";
import AlbumRow from "../../components/albumRow/albumRow";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const AllAlbum = () => {
  const [albums, setAlbums] = useState([]);
  const [adminSearch, setAdminSearch] = useState("");
  const [totalPage,setTotalPage]=useState(1)
  const [currentPage,setcurrentPage]=useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    

      GetAllAlbumSearchApi(currentPage, 5, adminSearch).then(res=>{

        console.log(res)
        setAlbums(res?.data?.data)
        setTotalPage(res?.data?.totalPages)
      }).catch(err=>{
        console.error(err)
      });
       
  }, [currentPage,adminSearch]);
  const addnewAlbum = ()=>{
    navigate("/upload-album")
  }
  const adminSearchAlbums=(e)=>{
    setcurrentPage(0)
    setAdminSearch(e.target.value)
  }
  const handleChange = (event, value) => {
    setcurrentPage(value-1)
  };
  return (
    <div className="allalbums">
      <CustomNavbar />
      <div className="videos-list-title-with-search">
        <input type="text" placeholder="Search" onChange={adminSearchAlbums}/>
        <h2>Albums</h2>
        <Button variant="outlined" onClick={addnewAlbum}>Add New Album</Button>
      </div>
      <div className="allalbums-list-in-columns">
      <AlbumRow 
                albumType="Set Premium"
                description="Description"
                price="Price"
                status="status"
                thumbnailUrl="Thumbnail"
                title="Title"
                showButton="No"
              />
        {albums.map((album, i) => {
          return (
            <div key={i} >
              <AlbumRow 
                albumId={album?.albumId}
                albumType={album?.albumType}
                description={album?.description}
                price={album?.price}
                status={album?.status}
                thumbnailUrl={album?.thumbnailUrl}
                title={album?.title}
                showButton="Yes"
              />
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center',marginTop:"2rem" }}>
        <Stack spacing={2}>
          <Pagination count={totalPage} size="large" onChange={handleChange}/>
        </Stack>
      </div>
    </div>
  );
};

export default AllAlbum;
