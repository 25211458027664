import React, { useState, useEffect } from "react";
import "./css/Videolist.css";
import Navbar from "../components/CustomNavbar";
import { GetAllVideosApi } from "../services/videoApi";
import { Button } from "@mui/material";
import VideoRow from "../components/videoRow/videoRow";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
const Videolist = () => {
  const [videoList, setVideoList] = useState([]);
  const [adminSearch, setAdminSearch] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setcurrentPage] = useState(0);
  // const [resultSize, setResultSize] = useState(2);
  const [toalRecordesInList, settoalRecordesInList] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    GetAllVideosApi(currentPage, 5, adminSearch)
      .then((res) => {
        console.log(res?.data);
        setTotalPage(res?.data?.totalPages);
        setVideoList(res?.data?.data);
        settoalRecordesInList(res?.data?.totalRecords)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setVideoList, adminSearch, currentPage,toalRecordesInList]);
  const addnewVideo = () => {
    navigate("/createvideo");
  };
  const adminSearchAlbums = (e) => {
    setcurrentPage(0);
    setAdminSearch(e.target.value);
  };
  const handleChange = (event, value) => {
    setcurrentPage(value - 1);
  };
  // const changePageSize =(num)=>{
  //   if(toalRecordesInList>resultSize && resultSize > 1 ){
  //   setResultSize(resultSize+num)
  //   }
  // }
  return (
    <div className="video-list-container background-image">
      <Navbar />
      <div className="videos-list-title-with-search">
      <input
            type="text"
            placeholder="Search video"
            value={adminSearch}
            onChange={adminSearchAlbums}
          />
        
        <h2>Videos</h2>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
         
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            {/* <Button onClick={()=>changePageSize(-1)}>-</Button>
            <Button>{resultSize}</Button>
            <Button onClick={()=>changePageSize(+1)}>+</Button> */}
            <Button variant="contained" onClick={addnewVideo}>
          Add New Video
        </Button>
          </ButtonGroup>
         
        </Box>
      </div>
      <div>
        <VideoRow
          videoType="Set Premium"
          description="Description"
          price="Price"
          status="status"
          thumbnailUrl="Thumbnail"
          title="Title"
          showButton="No"
        />
        {videoList.map((video, i) => {
          return (
            <div key={i}>
              <VideoRow
                videoId={video?.id}
                videoType={video?.videoType}
                description={video?.description}
                price={video?.price}
                status={video?.status}
                thumbnailUrl={video?.thumbnailUrl}
                title={video?.title}
                updateVideo={video}
                showButton="Yes"
              />
            </div>
          );
        })}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <Stack spacing={2}>
          <Pagination count={totalPage} size="large" onChange={handleChange} />
        </Stack>
      </div>
    </div>
  );
};
export default Videolist;
