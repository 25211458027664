import React, { useEffect, useState } from "react";
import { GetAlbum, UpdateAlbum } from "../../services/audioAlbum";
import CustomNavbar from "../CustomNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import "./albumDetails.css";
import AudioRow from "../albumRow/audioRow";
import Button from 'react-bootstrap/Button';

const AlbumDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [singleAlbum, setSingleAlbum] = useState({});
  const [getAllAudios, setgetAllAudios] = useState([]);
  const [audioUrl, setAudioUrl] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [updateApiCall, setUpdateApiCall] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    price: "",
  });
  const [updateAlubm, setUpdateAlbum] = useState(false);

  useEffect(() => {
    if (location.state === null || location.state.id === undefined) {
      navigate("/albums");
    } else {
      GetAlbum(location.state.id)
        .then((res) => {
          console.log(res.data);
          setSingleAlbum(res.data?.audioAlbum);
          setFormValues({
            title: res.data?.audioAlbum.title,
            description: res.data?.audioAlbum.description,
            price: res.data?.audioAlbum.price,
          });
          setgetAllAudios(res.data?.audio);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [location.state, navigate, updateAlubm, updateApiCall]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel =()=>{
    setEditMode(false);
  }
  const handleFileChange = (e) => {
    setThumbnailFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("description", formValues.description);
    formData.append("price", formValues.price);
    if (thumbnailFile) {
      formData.append("thumbnailUrl", thumbnailFile);
    }

    UpdateAlbum(singleAlbum.albumId, formData)
      .then((res) => {
        console.log(res);
        setUpdateAlbum(!updateAlubm);
        setSingleAlbum(res.data?.audioAlbum);
        setEditMode(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getAudioUrlUserClick = (url, id) => {
    setAudioUrl(url);
    setgetAllAudios(
      getAllAudios.map((allaudios) => {
        if (allaudios.id === id) {
          return { ...allaudios, isPlaying: true };
        } else {
          return { ...allaudios, isPlaying: false };
        }
      })
    );
  };

  return (
    <div>
      <CustomNavbar />
      <div className="albumdetails">
        <div className="albumdetails-information">
          {!editMode && (
            <div className="album-detils">
              <div className="album-detils-img">
                <img src={singleAlbum?.thumbnailUrl} alt="thubnail" />
              </div>
              <div>
                <h2>{singleAlbum?.title}</h2>
                <p>{singleAlbum?.description}</p>
                <b>{singleAlbum?.price}</b>
                <div>
                  <Button onClick={handleEdit} variant="primary">
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div>{editMode && 
          <div className="album-edit-edit-form">
              <div>    <label>Album Thubnail </label> <input type="file" onChange={handleFileChange} /></div>
              <div> 
              <label>Album Title</label>
              <input
                  type="text"
                  name="title"
                  value={formValues.title}
                  onChange={handleInputChange}
                  placeholder="Title"
                /></div>
              <div>  
              <label>Album Description</label>
              <textarea
                  name="description"
                  value={formValues.description}
                  onChange={handleInputChange}
                /></div>
              <div> 
              <label>Album Price</label>
              <input
                  type="text"
                  name="price"
                  value={formValues.price}
                  onChange={handleInputChange}
                /></div>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleCancel} variant="danger">Cancel</Button>
          </div>
          }
          </div>
        </div>
        <div>
          <AudioRow
            audioType="Set Premium"
            description="Description"
            price="Price"
            status="status"
            thumbnailUrl="Thumbnail"
            title="Title"
            showButton="No"
          />
          {getAllAudios.map((audio_single, i) => {
            return (
              <div key={i}>
                <AudioRow
                  audioId={audio_single?.id}
                  audioType={audio_single?.audioType}
                  description={audio_single?.description}
                  price={audio_single?.price}
                  status={audio_single?.status}
                  thumbnailUrl={audio_single?.thumbnailUrl}
                  title={audio_single?.title}
                  showButton="Yes"
                  audioPlay={getAudioUrlUserClick}
                  updateApiCallDetails={setUpdateApiCall}
                  isAudioPlaying={
                    audioUrl.length > 0 ? audio_single?.isPlaying : false
                  }
                  audioCount={audio_single?.viewCount}
                  audioObject={audio_single}
                  albumObject={singleAlbum}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="audio-play-div">
        {audioUrl.length > 0 ? (
          <audio src={audioUrl} alt="audio" autoPlay controls />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default AlbumDetails;
