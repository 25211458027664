import React, { useEffect, useState } from 'react';
import './userSubscription.css';
import Navbar from '../../components/CustomNavbar';
import { GetSubscriptionListApi } from "../../services/audioAlbum";

const UserSubscriptionList = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchSubscriptionList = async () => {
            try {
                const response = await GetSubscriptionListApi();
                console.log(response.data)
                setSubscriptions(response.data);
                setFilteredSubscriptions(response.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchSubscriptionList();
    }, []);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedSubscriptions = [...filteredSubscriptions].sort((a, b) => {
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);

            if (dateA < dateB) return direction === 'ascending' ? -1 : 1;
            if (dateA > dateB) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setFilteredSubscriptions(sortedSubscriptions);
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filteredData = subscriptions.filter((subscription) =>
            subscription.user.user_name.toLowerCase().includes(value) ||
            subscription.user.email.toLowerCase().includes(value) ||
            subscription.user?.contact?.toLowerCase().includes(value)
        );

        setFilteredSubscriptions(filteredData);
    };

    const calculateTotalPrice = () => {
        return filteredSubscriptions.reduce((total, subscription) => {
            return total + subscription.subscriptionPlan.price;
        }, 0);
    };

    return (
        <div>
            <Navbar />
            <div className="subscription-table">
                <input
                    type="text"
                    className="search-bar"
                    placeholder="Search by User Name, Email, or Mobile"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <table>
                    <thead>
                        <tr>
                            <th>Subscription ID</th>
                            <th>User email</th>
                            <th>User name</th>
                            <th>Mobile</th>
                            <th>Subscription Type</th>
                            <th>Rupees</th>
                            <th className='pointer_table_option' onClick={() => handleSort('fromDate')}>
                                Start Date
                            </th>
                            <th className='pointer_table_option' onClick={() => handleSort('toDate')}>
                                End Date
                            </th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSubscriptions.map((subscription, i) => (
                            <tr key={i}>
                                <td>{subscription.userSubscriptionPlansId}</td>
                                <td>{subscription.user.email}</td>
                                <td>{subscription.user.user_name}</td>
                                <td>{subscription.user?.contact}</td>
                                <td>{subscription.subscriptionPlan.subscriptionPlanName}</td>
                                <td>{subscription.subscriptionPlan.price}</td>
                                <td>{new Date(subscription.fromDate).toLocaleDateString()}</td>
                                <td>{new Date(subscription.toDate).toLocaleDateString()}</td>
                                <td
                                    className={
                                        subscription.status === 'Active'
                                            ? 'status-active'
                                            : 'status-expired'
                                    }
                                >
                                    {subscription.status}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
                            <td style={{ fontWeight: 'bold' }}>{calculateTotalPrice()}</td>
                            <td colSpan="3"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserSubscriptionList;