import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MdOutlineModeEditOutline } from "react-icons/md";
import "./updateAlbumAlertBox.css";
import Switch from "react-switch";
import { UpdateAlbum } from "../../services/audioAlbum";
import { toast } from "react-toastify";
const UpdateAlbumAlertBox = ({ requestUpdateAlbum,apiReacall }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [status, setAlbumStatus] = useState(false);
  const [premimum, setPremimum] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [loading,setLoading]=useState(false);
  useEffect(()=>{
    setTitle(requestUpdateAlbum?.title)
    setDescription(requestUpdateAlbum?.description)
    setAlbumStatus(requestUpdateAlbum?.status==="Active")
    setPremimum(requestUpdateAlbum?.albumType==="Premium")
    setPrice(requestUpdateAlbum?.price)
  },[requestUpdateAlbum])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (id) => {
    setLoading(true)
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    if (thumbnail) {
      formData.append("thumbnailUrl", thumbnail);
    }
    if (status) {
      formData.append("status", "Active");
    } else {
      formData.append("status", "Deactive");
    }
    if (premimum) {
      formData.append("albumType", "Premium");
      if(price<=0){
        toast.error("price is required")
        return ;
    }
    } else {
      formData.append("albumType", "Free");
    }
    
    const response = await UpdateAlbum(id,formData);
    console.log(response)
    if(response?.status===200){
      apiReacall(id)
      setLoading(false)
    toast.success("Album updated")
    setOpen(false);
    }else{
      setLoading(false)
      toast.error("Album is not updated")
    }

  };
  const handleAlbumStatus = (id) => {
    setAlbumStatus(!status)
    
  };
  const handleAlbumPremium = (id) => {
    setPremimum(!premimum)
  };

  const handleThumbnailChange = (event) => {
    setThumbnail(event.target.files[0]);
  };

  return (
    <React.Fragment>
      <Button>
        <MdOutlineModeEditOutline onClick={handleClickOpen} size="1.5rem" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Album"}</DialogTitle>
        <DialogContent>
          <div className="updateAudioAlertBox">
            <div>
              <label>Album Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <label>Album Description</label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <label>Album Price</label>
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div>
              <label>Select Thumbnail</label>
              <input
                type="file"
                onChange={handleThumbnailChange}
                accept="image/*"
              />
            </div>
            <div>
              <b>Set Active</b>
              <Switch
                onChange={() => handleAlbumStatus(requestUpdateAlbum?.albumId)}
                checked={status}
              />
            </div>
            <div>
              <b>Set Premimum</b>
              <Switch
                onChange={() => handleAlbumPremium(requestUpdateAlbum?.albumId)}
                checked={premimum}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}    disabled={loading} >Cancel</Button>
          <Button onClick={() => handleSubmit(requestUpdateAlbum?.albumId)}    disabled={loading} autoFocus>
          {loading ? "Loading..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateAlbumAlertBox;
