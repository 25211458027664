import React, { useState, useEffect } from 'react';
import './Videosearch.css';
import { Link } from 'react-router-dom';
import { getVideoList } from '../services/apiCallServices';
import { getToken } from '../services/token';
import Navbar from '../components/CustomNavbar';

const Managevideo = () => {
  const [videoList, setVideoList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8); 

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const token = getToken();
        const data = await getVideoList(token);
        setVideoList(data);
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    };

    fetchVideoData();
  }, []);

  const filteredVideos = videoList.filter(video =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredVideos.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="video-background">
         <video autoPlay muted loop className="video">
        <source src={require('../images/background.mp4')} type="video/mp4" />
      </video>
      <div className="video-app">
      <Navbar />
        <div className="controls">
          <input
            type="text"
            placeholder="Search videos"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className='searchh'
          />
        </div>
        <div className="video-list">
          {currentItems.map(video => (
            <div key={video.id} className="video-item">
              <Link to={`/video/${video.id}`}>
                <img src={video.thumbnailUrl} alt={video.title} className='video-img1' />
              </Link>
              <p>{video.title}</p>
            </div>
          ))}
        </div>
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredVideos.length / itemsPerPage) }).map((_, index) => (
            <button key={index} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Managevideo;