import React, { useState, useRef, useEffect } from "react";
import "./css/createaudio.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/CustomNavbar";
import { getToken } from "../services/token";
import { uploadAudioContent } from "../services/apiCallServices";
import { GenrateAudioUrl, UpdateAudio } from "../services/audioAlbum";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAllAudioInAlbumByIdApiData } from "../services/getApiData";
import UpdateAlbumAlertBox from "../components/Album/UpdateAlbumAlertBox";
import { IoMdAddCircleOutline } from "react-icons/io";
import Button from "@mui/material/Button";
import DeleteAudio from "../components/AlbumDetails/DeleteAudio";
const Createaudio = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [audioFile, setaudioFile] = useState(null);
  const [audioStatus, setStatus] = useState("Active");
  const [uploadMessage, setUploadMessage] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [audiosInAlbums, setAudiosInAlbums] = useState([]);
  const [updateAudio, setUpdateAudio] = useState(false);
  const [audioId, setAudioId] = useState(1);
  const [albumId, setAlbumId] = useState(1);
  const [audioThumbnail, setAudioThbnail] = useState("");
  const [loading, setLoading] = useState(false);
  const [timing, setTiming] = useState("");
  const [upadteAlbum, setUpdateAlbum] = useState({});
  const [hitApi, setHitApi] = useState(undefined);
  useEffect(() => {
    if (location.state != null) {
      ApiCallAllAudiosList(location.state?.album); //we only get id here
      setAlbumId(location.state?.album);
    } else {
      navigate("/albums");
    }
  }, [location, navigate]);
  useEffect(() => {
    if (hitApi !== undefined) {
      ApiCallAllAudiosList(hitApi);
    }
  }, [hitApi]);
  const ApiCallAllAudiosList = (id) => {
    GetAllAudioInAlbumByIdApiData(id)
      .then((res) => {
        setAudiosInAlbums(res?.audio);
        setUpdateAlbum(res?.audioAlbum);
        setSelectedAlbum(res?.audioAlbum?.title);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const imageInputRef = useRef(null);
  const audioInputRef = useRef(null);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setUploadMessage("");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setUploadMessage("");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setUploadMessage("");
  };
 
  const handleAudioUpload = async(e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedAudioTypes = ["audio/mpeg", "audio/mp3"];
      if (allowedAudioTypes.includes(file.type)) {
        try {
          const audio = new Audio(URL.createObjectURL(file));
          audio.onloadedmetadata = () => {
            const durationInSeconds = Math.floor(audio.duration);
          
            // Convert duration to minutes and seconds
            const minutes = Math.floor(durationInSeconds / 60);
            const seconds = durationInSeconds % 60;
            
            // Format duration as "minutes:seconds"
            const formattedDuration = `${minutes}:${seconds.toString().padStart(2, '0')}`;
            console.log("Audio duration:", formattedDuration);
  
            // Set duration to state or wherever you need
            setTiming(formattedDuration);
          };
        } catch (error) {
          console.error("Error getting audio duration:", error);
        }
        setaudioFile(file);
        setAudioUrl(file)
        setUploadMessage("");
      } else {
        setUploadMessage("Please select a valid audio file");
      }
    }
  };

  const handleStatusChange = (e) => {
    const selectedValue = e.target.value;
    setStatus(selectedValue);
    setUploadMessage("");
  };

  const cancelUpdate = () => {
    setTitle("");
    setDescription("");
    setTiming("");
    setImageFile(null);
    setaudioFile(null);
    setUpdateAudio(false);
    setUploadMessage("");
    setAudioThbnail("");
    setAudioId(0)
    
  };
  const handleSave = () => {
    setLoading(true);
    let isValid = true;
    if (selectedAlbum.trim() === "") {
      setUploadMessage("Please Select Album");
      isValid = false;
    } else if (title.trim() === "") {
      setUploadMessage("Please enter a title.");
      isValid = false;
    } else if (description.trim() === "") {
      setUploadMessage("Please enter description.");
      isValid = false;
    } else if (imageFile === null) {
      setUploadMessage("Please upload an image.");
      isValid = false;
    } else if (audioFile === null) {
      setUploadMessage("Please upload an audio file.");
      isValid = false;
    } else if (audioStatus === "") {
      setUploadMessage("Please select a status.");
      isValid = false;
    } else if (timing.trim() === "") {
      setUploadMessage("Please enter a time.");
      isValid = false;
    }

    if (isValid) {
      const formData = new FormData();
      formData.append("album", selectedAlbum);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("status", audioStatus);
      formData.append("audioFile", audioFile);
      formData.append("thumbnailUrl", imageFile);
      formData.append("audio_length", timing);

      uploadAudioContent(formData, getToken(), (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);

      })
        .then((response) => {
          setLoading(false);
          toast.success("Audio content uploaded successfully!");
          setTitle("");
          setDescription("");
          setTiming("");
          setImageFile(null);
          setaudioFile(null);
          setUploadProgress(0);
          ApiCallAllAudiosList(albumId);

          if (imageInputRef.current) {
            imageInputRef.current.value = "";
          }
          if (audioInputRef.current) {
            audioInputRef.current.value = "";
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          
          if(error?.response?.status===409){
            toast.error("Audio title is alredy exiting");
          }else{
            toast.error("Error uploading audio content. Please try again.");
          }
         
        });
    }else{
      setLoading(false);
    }
  };
  const updateExistingAudio = (id) => {
    setLoading(true);
    const formData = new FormData();
    if (imageFile != null) {
      formData.append("thumbnailUrl", imageFile);
    }
    if (audioFile != null) {
      formData.append("audioFile", audioFile);
    }
    formData.append("status", audioStatus);

    formData.append("title", title);
    formData.append("description", description);
    formData.append("audio_length", timing);
    UpdateAudio(id, formData)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Audio content updated successfully!");
          ApiCallAllAudiosList(albumId);
          setLoading(false);
        }
      })
      .catch((err) => {
        if(err?.response?.status===409){
          toast.error("Audio title alredy exists");
        }else{
          toast.error("Audio content not Updated successfully!");
          setLoading(false);
          console.error(err);
        }
       
      });
  };
  const handleSetNewAudioToUpdate = async (updateAudioData) => {
    try {
      const response = await GenrateAudioUrl(updateAudioData?.id, 1000);
      setAudioUrl(response.data);
    } catch (err) {
      console.error(err);
    }
    console.log(updateAudioData);
    setTitle(updateAudioData?.title);
    setDescription(updateAudioData?.description);
    setStatus(updateAudioData?.status);
    setAudioId(updateAudioData?.id);
    setTiming(updateAudioData?.audio_length);
    setAudioThbnail(updateAudioData?.thumbnailUrl);
    setUpdateAudio(true);
    setUploadMessage("");
  };
  return (
    <div className="createaudio-main">
      <Navbar />
      <div className="createaudio">
        <div className="audio-list-in-update">
          <div className="audio-list-in-update-album-label">
          <div>
         <img className="album-thubnail-preview-in-update-audio" src={upadteAlbum?.thumbnailUrl} alt="album thubnail"/> {selectedAlbum}
         </div>
            <div>
              <UpdateAlbumAlertBox
                requestUpdateAlbum={upadteAlbum}
                apiReacall={setHitApi}
              />
            </div>
          </div>

          <b className="title-audioList">Audio list <Button onClick={() => cancelUpdate()}>
                <IoMdAddCircleOutline size="1.5rem" />
              </Button></b>
          <div className="audio-list-scrollable">
            {audiosInAlbums.length > 0 &&
              audiosInAlbums.map((audio, id) => {
                return (
                  <div
                    key={id}
                    onClick={() => handleSetNewAudioToUpdate(audio)}
                    className={` ${
                      audioId === audio.id ? "audio-list-single-audio-selected" : "audio-list-single-audio"
                    }`}
                  >
                    <img
                      src={audio?.thumbnailUrl}
                      alt="thubnail"
                      className="audioThumbnail-in-audio-list"
                    />
                    <p>
                      <b>{audio?.title}</b>
                    </p>
                    <DeleteAudio audioId={audio.id} />
                  </div>
                );
              })}
          </div>
        </div>

        <div className="uploadAudio">
          {!updateAudio ? <h2>Upload Audio</h2> : <h2>Update Audio</h2>}
          <div className="createaudio-allinputs">
            <div className="album-with-audios">
              <div className="select-album-audioList"></div>
            </div>
          </div>
          <div className="createaudio-allinputs">
            <label>Audio Title:</label>
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter Title"
            />
          </div>
          <div className="createaudio-allinputs">
            <label>Audio Description:</label>
            <textarea
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter Description"
            />
          </div>
          <div className="audio-file-select-and-play">
            <label htmlFor="audioFileInput" className="custom-file-upload">
              {updateAudio ? (
                <label>Select to Update Thumbnail Previous Thumbnail</label>
              ) : (
                <label>Select an audio thumbnail</label>
              )}
              <input
                id="audioFileInput"
                type="file"
                accept="image/*"
                className="file-selector"
                onChange={handleImageUpload}
                ref={imageInputRef}
              />
            </label>
            {updateAudio && (
              <img
                className="audioThumbnail-in-update-audio"
                src={audioThumbnail}
                alt="thubnail"
              />
            )}
          </div>
          <div className="audio-file-select-and-play">
            <label htmlFor="audioFileInput" className="custom-file-upload">
              {updateAudio ? (
                <label> Select to update audio file previous audio</label>
              ) : (
                <label> Select an audio file</label>
              )}

              <input
                id="audioFileInput"
                type="file"
                className="file-selector"
                accept="audio/mpeg, audio/mp3"
                onChange={handleAudioUpload}
                ref={audioInputRef}
              />
            </label>
            {updateAudio && <audio src={audioUrl} alt="audio" controls />}
          </div>
          <div className="createaudio-allinputs">
            <label>Audio Timing: {timing}</label>
           
          </div>
          <div className="createaudio-allinputs">
            <label>Status:</label>
            <select value={audioStatus} onChange={handleStatusChange}>
              <option value="Active">Active</option>
              <option value="Deactive">Deactive</option>
            </select>
            <br />
          </div>
          {uploadProgress > 0 && (
            <div className="progress1">
              <div
                className="progress-bar1"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          )}
          <br />
          {!updateAudio ? (
            loading ? (
              <Button variant="contained" >
                Loading ....
              </Button>
            ) : (
              <Button onClick={handleSave} variant="contained">
                Upload
              </Button>
            )
          ) : loading ? (
            <Button variant="secondary">Loading ....</Button>
          ) : (
            <div className="audio-update-buttons">
              <Button
                variant="contained"
                color="success"
                onClick={() => updateExistingAudio(audioId)}
              >
                Update Audio
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => cancelUpdate()}
              >
                Cancel Update
              </Button>
            </div>
          )}

          <div className={`message${uploadMessage ? " show" : ""}`}>
            {uploadMessage}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Createaudio;
