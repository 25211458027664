import React, { useState } from "react";
import "./Login.css";
// import { RiLockPasswordFill } from 'react-icons/ri';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginUser, forgotPassword } from '../services/apiCallServices'; 
import { getToken, setToken, removeToken } from '../services/token'; 

const Login = () => {
  
  const [isLoginFormVisible, setLoginFormVisible] = useState(true);
  const [isForgotPasswordVisible, setForgotPasswordVisible] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: "",
  });

  const [forgotPasswordEmailError, setForgotPasswordEmailError] = useState("");

  const [loginError, setLoginError] = useState("");

  const toggleLoginForm = () => {
    setLoginFormVisible(!isLoginFormVisible);
    setForgotPasswordVisible(false);
  };

  const toggleForgotPassword = () => {
    setForgotPasswordVisible(true);
    setLoginFormVisible(false);
  };

  const validateLoginForm = () => {
    let isValid = true;

    if (!loginData.email) {
      setEmailError("Please enter your email.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!loginData.password) {
      setPasswordError("Please enter your password.");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const validateForgotPasswordForm = () => {
    let isValid = true;

    if (!forgotPasswordData.email) {
      setForgotPasswordEmailError("Please enter your email.");
      isValid = false;
    } else {
      setForgotPasswordEmailError("");
    }

    return isValid;
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
  
    if (validateLoginForm()) {
      try {
        const response = await loginUser(loginData); // Use the loginUser function from api-services
        console.log("Logged in with email:", loginData.email);
        console.log("User Data:", response);
  
        setLoginError("");
        setToken(response.token);
  
        toast.success("Login successful!");
        toast("Welcome, " + response.username, { type: "info" });
  
        window.location.href = "/dashboard";
      } catch (error) {
        console.error("An error occurred:", error);
        setLoginError("Invalid login!");
        if (error.message === "Token expired") {
          removeToken();
          window.location.href = "/login"; 
        }
      }
    }
  };
  
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();

    if (validateForgotPasswordForm()) {
      try {
        const token = getToken();
        const response = await forgotPassword(forgotPasswordData, token); // Use the forgotPassword function from api-services

        console.log(response);
        setToken(response.token);
          
      } catch (error) {
        console.error("An error occurred:", error);
        setForgotPasswordEmailError(error.message); 
      }
    }
  };

  return (
    <>
      <header className="header">
        <nav className="nav">
        </nav>
      </header>
      <section className="home">
        {isLoginFormVisible && !isForgotPasswordVisible && (
          <div className="form_container">
            <form onSubmit={handleLoginSubmit}>
              <h2>Login</h2>
              {loginError && <div className="error-message">{loginError}</div>}
              <div className="input_box">
                <input
                  type="email"
                  placeholder="Enter your Email"
                  value={loginData.email}
                  onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                />
                <div className="error-message">{emailError}</div>
              </div>
              <div className="input_box">
                <input
                  type="password"
                  placeholder="Enter your password"
                  value={loginData.password}
                  onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                />
                <div className="error-message">{passwordError}</div>
              </div>
              <div className="option_feild">
                <div className="forgot_pw" onClick={toggleForgotPassword}>
                  Forgot Password?
                </div>
              </div>
              <button className="button">Login</button>
            </form>
          </div>
        )}

        {isForgotPasswordVisible && (
          <div className="form_container">
            <i className="uil uil-times" onClick={toggleForgotPassword}></i>
            <form onSubmit={handleForgotPasswordSubmit}>
              {/* <RiLockPasswordFill className="lock-icon" /> */}
              <h2>Forgot Password</h2>
              <div className="input_box">
                <input
                  type="email"
                  placeholder="Enter your Email"
                  value={forgotPasswordData.email}
                  onChange={(e) => setForgotPasswordData({ ...forgotPasswordData, email: e.target.value })}
                />
                <div className="error-message">{forgotPasswordEmailError}</div>
              </div>
              <button className="button">Reset Password</button>
              <div className="login_signup">
                Remember your password? <div onClick={toggleLoginForm}>Login</div>
              </div>
            </form>
          </div>
        )}
      </section>
    
    </>
  );
};

export default Login;
