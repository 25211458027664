import React, { useEffect, useState } from "react";
import "./videoRow.css";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import DeleteVideo from "../AlbumDetails/DeleteVideo";
import { Link } from "react-router-dom";
import { UpdateVideoStatus } from "../../services/videoApi";
import { toast } from "react-toastify";
const VideoRow = ({
  videoId,
  videoType,
  description,
  price,
  status,
  thumbnailUrl,
  title,
  showButton,
  updateVideo
}) => {
  const [videoStatus, setVideoStatus] = useState(false);
  const [checkVideoType, setVideoType] = useState(false);
  const [videoTypeState, setVideoTypeState] = useState(videoType);
  const navigate = useNavigate();
  useEffect(() => {
    setVideoStatus(status === "Active");
    setVideoType(videoType === "Premium");
    setVideoTypeState(videoType);
  }, [status, videoType]);
  const shortTitle = title.substring(0, 20) + (title.length > 20 ? "..." : "");

  const shortDescription =
    description.substring(0, 20) + (description.length > 20 ? "..." : "");
  const handlevideoStatus = (id) => {
    const formData = new FormData();
    if (!videoStatus) {
      formData.append("status", "Active");
    } else {
      formData.append("status", "Deactive");
    }
    UpdateVideoStatus(id,formData).then(res=>{
      console.log(res);
      setVideoStatus(!videoStatus)
      toast.success("Video Status updated")
    }).catch(err=>{
      toast.error("Video Status is not updated")
      console.log(err)
    })
  };
  const handlevideoType = (id,type) => {
    const formData = new FormData();
    formData.append("videoType", type);
    UpdateVideoStatus(id,formData).then(res=>{
      console.log(res);
      setVideoType(!checkVideoType)
      setVideoTypeState(type);
      toast.success("Video Type is  updated")
    }).catch(err=>{
      toast.error("Video Type is not updated")
      console.log(err)
    })
  };
  const handleGoToEditVideo = (video) => {
    navigate("/createvideo", { state: { video: video } });
  };
  return (
    <div className="videoRow">
      <div className="video-thumbnail">
        {thumbnailUrl === "Thumbnail" ? (
          <b>Thumbnail</b>
        ) : (
          <Link to={`/video/${videoId}`}>
            <img src={thumbnailUrl} alt="video thumbnail" />
          </Link>
        )}
      </div>
      <div className="title">
        <b>{shortTitle}</b>
      </div>

      <div className="description">
        <b>{shortDescription}</b>
      </div>
      <div className="videoprice">
        <b>{price}₹</b>
      </div>
      <div className="videoType">
        {videoType === "Set Premium" ? (
          <b>Set Premium</b>
        ) : (
          <select
          className="video-type-select"
      value={videoTypeState}
      onChange={(e) => handlevideoType(videoId, e.target.value)}
    >
      <option value="Free">Free</option>
      <option value="Premium">Premium</option>
      <option value="BuyOne">BuyOne</option>
    </select>
        )}
      </div>
      <div className="status">
        {status === "status" ? (
          <b>Status</b>
        ) : (
          <Switch
            onChange={() => handlevideoStatus(videoId)}
            checked={videoStatus}
          />
        )}
      </div>
      <div className="edit">
        {showButton === "No" ? (
          <b>Edit</b>
        ) : (
          <MdOutlineModeEditOutline
            onClick={() => handleGoToEditVideo(updateVideo)}
            size="1.5rem"
          />
        )}
      </div>
      <div className="delete">
        {showButton === "No" ? (
          <b>Delete</b>
        ) : (
          <DeleteVideo videoId={videoId} />
        )}
      </div>
    </div>
  );
};

export default VideoRow;
