import React, { useState, useEffect, useRef } from 'react';
import './Createvideo.css';
import { uploadVideoContent } from '../services/apiCallServices';
import { getToken } from '../services/token';
import {  toast } from 'react-toastify';
import Navbar from '../components/CustomNavbar';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import { UpdateVideoApi } from '../services/videoApi';
const Createvideo = () => {
  const navigate=useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [status, setStatus] = useState('Active');
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [price, setPrice] = useState('');
  const [videoType, setVideoType] = useState('Free');
  const [videoTime, setVideoTime] = useState('');
  const [updateVideo,setUpdateVideo]=useState(false)
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [videoId,setVideoId]=useState(null);
  
  const location=useLocation();



  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedVideoTypes = ["video/mp4", "video/mpeg", "video/webm"];
      if (allowedVideoTypes.includes(file.type)) {
        try {
          const video = document.createElement("video");
          video.src = URL.createObjectURL(file);
          video.preload = "metadata";
  
          video.onloadedmetadata = () => {
            const durationInSeconds = Math.floor(video.duration);
            console.log(durationInSeconds);
            setVideoTime(durationInSeconds);
          };
  
          video.load();
        } catch (error) {
          console.error("Error getting video duration:", error);
        }
        setVideoFile(file);
        setUploadMessage("");
      } else {
        setUploadMessage("Please select a valid video file");
      }
    }
  };

  const handlevideoTypeChange = (e) => {
    setVideoType(e.target.value);
  }

  const handlestatusChange = (e) => {
    const selectedValue = e.target.value;
    setStatus(selectedValue);
  };

  const handleSave = () => {
    let isValid = true;
    

    if (title.trim() === '') {
      setUploadMessage('Please enter a title.');
      isValid = false;
    } else if (description.trim() === '') {
      setUploadMessage('Please enter description.');
      isValid = false;
    } else if (imageFile === null) {
      setUploadMessage('Please upload an image.');
      isValid = false;
    } else if (videoFile === null) {
      setUploadMessage('Please upload a video.');
      isValid = false;
    } else if (status === '') {
      setUploadMessage('Please select a status.');
      isValid = false;
    } else if (videoType === '') {
      setUploadMessage('Please select a video type.');
      isValid = false;
    } else if (price.trim() === '') {
      setUploadMessage('Please enter a price.');
      isValid = false;
    }

    if (isValid) {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('status', status);
      formData.append('videoType', videoType);
      formData.append('videoFile', videoFile);
      formData.append('price', price)
      formData.append('thumbnailFile', imageFile);
      formData.append('videoTimeGet', videoTime);

      uploadVideoContent(formData, getToken(), (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      })

        .then(response => {
          // setUploadMessage('Content uploaded successfully!');
          toast.success('Content uploaded successfully!');
          setTitle('');
          setDescription('');
          setImageFile(null);
          setVideoFile(null);
          setStatus('');
          setPrice('');
          setVideoType('');
          setUploadProgress(0); 

          if (imageInputRef.current) {
            imageInputRef.current.value = '';
          }
          if (videoInputRef.current) {
            videoInputRef.current.value = '';
          }
          navigate("/videolist")
        })
          
  
        
        .catch(error => {
          console.error('Error:', error);
          // setUploadMessage('Error uploading content. Please try again.');
          toast.error('Error uploading content. Please try again.');
        });
    }
  };
  useEffect(() => {
    if (location.state != null) {
      setVideoId(location.state?.video?.id)
      setTitle(location.state?.video?.title);
          setDescription(location.state?.video?.description);
          setStatus(location.state?.video?.status);
          setPrice(location.state?.video?.price);
          setVideoType(location.state?.video?.videoType);
          setUploadProgress(0); 
          setUpdateVideo(true)
    } 
  }, [location]);
  const cancelUpdate=()=>{
    setTitle('');
    setDescription('');
    setImageFile(null);
    setVideoFile(null);
    setStatus('');
    setPrice('');
    setVideoType('');
    setUploadProgress(0); 
    setUpdateVideo(false)
    navigate("/videolist")
  }
  
  const updateVideoData = (id)=>{
    let isValid=true;
    if (title.trim() === '') {
      setUploadMessage('Please enter a title.');
      isValid = false;
    } else if (description.trim() === '') {
      setUploadMessage('Please enter description.');
      isValid = false;
    } else if (status === '') {
      setUploadMessage('Please select a status.');
      isValid = false;
    } else if (videoType === '') {
      setUploadMessage('Please select a video type.');
      isValid = false;
    }
    if(isValid){
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('status', status);
    formData.append('videoType', videoType);
    if(videoFile){
      formData.append('videoFile', videoFile);
    }
    if(imageFile){
      formData.append('thumbnailFile', imageFile);
    }
    formData.append('price', price)
    UpdateVideoApi(id,formData,(progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadProgress(percentCompleted);
    }).then(res=>{
      toast.success(res?.data);
      navigate("/videolist")
    }).catch(err=>{
      toast.error('Error Updating video. Please try again.');
      console.error(err);
    })
  }else{
    return;
  }
  }
  return (
    <div className='backgroung-images'>
    <Navbar />
        <div className="container1">
          <h2>{!updateVideo ? "Create Video ": "Update Video"}</h2>
          <label>Title:</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="text"
            placeholder='Enter Title'
          />
          <br />
          <label>Description:</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            className="text"
            placeholder='Enter Description'
          />
          <br />
          {updateVideo ? <label>Update Thubnail for Video:</label> : <label>Set Thubnail:</label>}
         
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="file-upload"
            ref={imageInputRef}
          />
          <br />
          {updateVideo ? <label>Video Update if you want to change video file:</label>:<label>Video Upload:</label>}
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
            className="file-upload"
             ref={videoInputRef}
          />
          <br />
          <div className="label-select-container">
          <label>Type:</label>
            <select
              value={videoType}
              onChange={handlevideoTypeChange}
              className="status-dropdown"
            >
              <option value="Free">Free</option>
              <option value="Premium">Premium</option>
              <option value="BuyOne">BuyOne</option>
            </select>
            <br />
            <label>Status:</label>
            <select
              value={status}
              onChange={handlestatusChange}
              className="status-dropdown"
            >
              <option value="Active">Active</option>
              <option value="Deactive">Deactive</option>
            </select>
            
           

            <label>Price:</label>
            <div className="price">
              <input
                type='number'
                value={price}
                onChange={handlePriceChange}
              />
            </div>
          </div>
          <br />
          {uploadProgress > 0 && (
            <div className="progress1">
              <div
                className="progress-bar1"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          )}
          <br />
        
          {updateVideo ?
          <Stack spacing={2} direction="row">
            <Button  className='uploadbtn' variant='contained' onClick={()=>updateVideoData(videoId)}>Update</Button>
            <Button  className='uploadbtn' variant='contained' onClick={cancelUpdate} color="error">Cancel Update</Button>
            </Stack>
          :
          <Button onClick={handleSave} className='uploadbtn' variant='contained'>Upload</Button>
          }
          <div className={`message${uploadMessage ? ' show' : ''}`}>
            {uploadMessage}
          </div>
        </div>
      </div>
     
  );
};

export default Createvideo;

