
// import React, { Component } from 'react';
// import './category.css';
// import { Link } from 'react-router-dom';
// import { FaEdit, FaTrash, FaSave } from 'react-icons/fa';
// import { getToken } from '../services/token'; // Import the getToken function
// import {
//   fetchCategories,
//   updateCategory,
//   deleteCategory,
// } from '../services/categories'; 
// import Navbar from '../components/Navbar';


// class MyTable extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       data: [],
//       editedData: null,
//       searchCategory: '',
//     };
//   }

//   componentDidMount() {
//     const token = getToken();
//     if (token) {
//       this.fetchCategories(token);
//     }
//   }

//   fetchCategories = (token) => {
//     fetchCategories(token)
//       .then((data) => {
//         this.setState({ data });
//       })
//       .catch((error) => {
//         console.error('Error fetching categories:', error);
//       });
//   };

//   handleInputChange = (event) => {
//     const { name, value } = event.target;
//     this.setState((prevState) => ({
//       editedData: {
//         ...prevState.editedData,
//         [name]: name === 'validFlag' ? value === 'true' : value,
//       },
//     }));
//   };

//   handleEditClick = (categoryId) => {
//     const categoryToEdit = this.state.data.find((category) => category.categoryId === categoryId);

//     if (categoryToEdit) {
//       this.setState({ editedData: { ...categoryToEdit } });
//     }
//   };

//   handleSaveClick = () => {
//     if (this.state.editedData) {
//       const token = getToken();

//       updateCategory(token, this.state.editedData.categoryId, this.state.editedData)
//         .then((updatedCategory) => {
//           const updatedData = this.state.data.map((category) => {
//             if (category.categoryId === updatedCategory.categoryId) {
//               return updatedCategory;
//             }
//             return category;
//           });

//           this.setState({ data: updatedData, editedData: null });
//         })
//         .catch((error) => {
//           console.error('Error updating category:', error);
//         });
//     }
//   };

//   handleDeleteClick = (categoryId) => {
//     const token = getToken();

//     deleteCategory(token, categoryId)
//       .then(() => {
//         console.log(`Category with ID ${categoryId} deleted.`);
//         this.setState((prevState) => ({
//           data: prevState.data.filter((category) => category.categoryId !== categoryId),
//         }));
//       })
//       .catch((error) => {
//         console.error('Error deleting category:', error);
//       });
//   };

//   handleSearchChange = (event) => {
//     this.setState({ searchCategory: event.target.value });
//   };

//   render() {
//     const filteredData = this.state.data.filter((item) =>
//       item.category &&
//       item.category.toLowerCase().includes(this.state.searchCategory.toLowerCase())
//     );

//     return (
//       <div className="background">
//       <Navbar />
//         <input
//           type="text"
//           className="searchcategory"
//           placeholder="Search by Category"
//           value={this.state.searchCategory}
//           onChange={this.handleSearchChange}
//         />
//         <table className="my-table">
//           <thead>
//             <tr>
//               <th className="table-header">ID</th>
//               <th className="table-header">Category</th>
//               <th className="table-header">Active Flag</th>
//               <th className="table-header">Edit</th>
//               <th className="table-header">Delete</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.map((item) => (
//               <tr key={item.categoryId} className="table-row">
//                 <td>{item.categoryId}</td>
//                 <td>
//                   {item.categoryId === (this.state.editedData && this.state.editedData.categoryId) ? (
//                     <input
//                       type="text"
//                       name="category"
//                       className="select2"
//                       value={this.state.editedData.category || ""}
//                       onChange={this.handleInputChange}
//                     />
//                   ) : item.category || ""}
//                 </td>
//                 <td>
//                   {item.categoryId === (this.state.editedData && this.state.editedData.categoryId) ? (
//                     <select
//                       name="validFlag"
//                       className='select'
//                       value={this.state.editedData.validFlag ? "true" : "false"}
//                       onChange={this.handleInputChange}
//                     >
//                       <option value="true">Active</option>
//                       <option value="false">Inactive</option>
//                     </select>
//                   ) : (this.state.editedData && this.state.editedData.categoryId) === item.categoryId ? (
//                     <select
//                       name="validFlag"
//                       value={this.state.editedData.validFlag ? "true" : "false"}
//                       onChange={this.handleInputChange}
//                     >
//                       <option value="true">Active</option>
//                       <option value="false">Inactive</option>
//                     </select>
//                   ) : item.validFlag ? "Active" : "Inactive"}
//                 </td>
//                 <td>
//                   {item.categoryId === (this.state.editedData && this.state.editedData.categoryId) ? (
//                     <FaSave onClick={this.handleSaveClick} />
//                   ) : (
//                     <FaEdit onClick={() => this.handleEditClick(item.categoryId)} />
//                   )}
//                 </td>
//                 <td>
//                   <FaTrash onClick={() => this.handleDeleteClick(item.categoryId)} />
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default MyTable;

import React from 'react';
import './VideoDetail.css';
import Progress from '../components/Progress'

const VideoDetail = () => {

return ( 
  <>
  <Progress />
  </>
);
};

export default VideoDetail;
