import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AiFillDelete } from "react-icons/ai";
import { DeleteAlbumApi } from '../../services/audioAlbum';
const DeleteAlbum = ({albumId}) => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (id)=>{
    setLoading(true)
    DeleteAlbumApi(id).then(res=>{
      setOpen(false);
      window.location.reload(); 
      setLoading(false)
        console.log(res)
      
    }).catch(err=>{
      setLoading(false)
        console.error(err)
    })
  }
  return (
    <React.Fragment>
    {/* <Button variant="outlined" >
      Open alert dialog
    </Button> */}
    <AiFillDelete onClick={handleClickOpen} size="1.5rem"/>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"You Really want to delete this Album"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          After clicking Agree you delete entire album with songs also
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={()=>handleDelete(albumId)} autoFocus>
          Agree
        </Button>
      </DialogActions> */}
      {loading ? (
          <DialogActions>
            <Button variant="contained">Deleting ....</Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={() => handleDelete(albumId)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        )}
    </Dialog>
  </React.Fragment>
  )
}

export default DeleteAlbum;