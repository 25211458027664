import React from 'react';
import Navbar from './CustomNavbar';
import './progress.css'

const VideoDetail = () => {

return ( 
  <>
  <div className="video-app">
      <Navbar />
      </div>
  <video autoPlay muted loop className="videop">
        <source src={require('../images/work.mp4')} type="video/mp4" />
      </video>

  </>
);
};

export default VideoDetail;