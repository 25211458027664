import { privateAxios } from "./helper";
export const GetAllAlbums = async () => {
    const response = await privateAxios.get(`/admin/video/all/albums`);
    return response;
}
export const GetAlbum = async (id) => {
    const response = await privateAxios.get(`/admin/album/get/byId/${id}`);
    return response;
}
export const UpdateAlbum = async (albumId,albumData) => {
    const response = await privateAxios.put(`/admin/video/update/album/${albumId}`,albumData);
    return response;
}
export const UpdateAudio = async (audioId,audioData) => {
    const response = await privateAxios.put(`/admin/video/update/audio/${audioId}`,audioData);
    return response;
}
export const UploadAlbumAdmin = async (albumData) => {
    const response = await privateAxios.post(`/admin/video/upload/album`,albumData);
    return response;
}
export const GenrateAudioUrl = async (id,expiration) => {
    const response = await privateAxios.get(`/admin/video/audio/url/${id}/${expiration}`);
    return response;
}
export const DeleteAudioApi =async (id) => {
    const response = await privateAxios.delete(`/admin/video/audio/delete/${id}`);
    return response;
}
export const DeleteAlbumApi =async (id) => {
    const response = await privateAxios.delete(`/admin/video/album/delete/${id}`);
    return response;
}
export const GetAllAlbumSearchApi = async (page = 0, size = 8, searchQuery = "") => {
    const response = await privateAxios.get(`/admin/album/search?page=${page}&size=${size}&searchQuery=${searchQuery}`);
    return response;
}
export const GetSubscriptionListApi = async () => {
    const response = await privateAxios.get(`/admin/video/get/all_subscription`);
    return response;
}
export const GetAllPurchasedAudioAlbumListApi = async () => {
    const response = await privateAxios.get(`/admin/video/get/all_list_buyed_video_audio`);
    return response;
}
export const GetAllUserFromDBApi = async () => {
    const response = await privateAxios.get(`/admin/video/get/all_user_list`);
    return response;
}