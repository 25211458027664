
import { getToken } from './token';
import axios from 'axios';
const apiCall = async (url, method, headers, body) => {
    try {
        const response = await fetch(url, {
            method,
            headers,
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};

const BASE_URL = 'https://apiadmin.drsunilkale.com/admin/video/upload'; 

export const uploadVideoContent = (formData, token, onUploadProgress) => {
  return axios.post(BASE_URL, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
};


export const uploadAudioContent = (formData, token, onUploadProgress) => {
  const BASE_URL = 'https://apiadmin.drsunilkale.com/admin/video/upload/audio';

  return axios.post(BASE_URL, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
};


export const loginUser = async (loginData) => {
    return apiCall("https://apiadmin.drsunilkale.com/auth/login", "POST", {
        "Content-Type": "application/json",
    }, loginData);
};

export const forgotPassword = async (forgotPasswordData, token) => {
    return apiCall("https://apiadmin.drsunilkale.com/admin/forgot-password", "PUT", {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
    }, forgotPasswordData);
};




export const fetchVideoList = async (token) => {
  try {
    const response = await axios.get('https://apiadmin.drsunilkale.com/admin/video', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateVideo = async (id, formData, token) => {
  try {
    const response = await axios.put(`https://apiadmin.drsunilkale.com/admin/video/update/${id}`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVideo = async (id, token) => {
  try {
    const response = await axios.delete(`https://apiadmin.drsunilkale.com/admin/video/delete/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getVideoById = async (videoId) => {
  try {
    const token = getToken();

    const response = await fetch(`https://apiadmin.drsunilkale.com/admin/video/${videoId}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok (${response.status} - ${response.statusText})`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching video data:', error.message);
    throw error; 
  }
};

export const getVideoUrlFromCloudFront = async (videoId, expireTime) => {
  try {
    const token = getToken();

    const response = await fetch(`https://apiadmin.drsunilkale.com/admin/video/url/${videoId}/${expireTime}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok (${response.status} - ${response.statusText})`);
    }

    const videoUrl = await response.text(); 
    return videoUrl;
  } catch (error) {
    console.error('Error fetching video URL:', error.message);
    throw error; 
  }
};


export const getVideoList = async (token, page, pageSize) => {
  try {
    const response = await apiCall(`https://apiadmin.drsunilkale.com/admin/video?page=${page}&pageSize=${pageSize}`, 'GET', {
      'Authorization': `Bearer ${token}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
  export const getAllTransactions = async (token, url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  };


// UserCount
const BASE_URLL = 'https://apiadmin.drsunilkale.com/admin/video/withUserCount';

const headers = {
  'Content-Type': 'application/json',
};

export const fetchData = async (token) => {
  try {
    const response = await fetch(BASE_URLL, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error('Error fetching data. Please check your authentication.');
  }
};




// apiCallService.js



const BASE_URRL = 'https://apiadmin.drsunilkale.com/admin/video/';  // Update with your base URL

export const fetchUserVideoCount = async () => {
  try {
    const apiUrl = `${BASE_URRL}withVideoCount`;

    const token = getToken();

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching user video count:', error);
    throw new Error('Error fetching user video count. Please try again.');
  }
};
export const createSubscriptionPlan = async (newPlan) => {
  try {
    const token = getToken();

    const response = await axios.post('https://apiadmin.drsunilkale.com/api/subscription-plans', newPlan, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error creating subscription plan:', error);
    throw error;
  }
};
export const fetchSubscriptionPlans = async () => {
  try {
    const token = getToken();

    const response = await axios.get('https://apiadmin.drsunilkale.com/api/subscription-plans', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching subscription plans:', error);
    throw error;
  }
};
export const updateSubscriptionPlan = async (subscriptionPlanId, updatedPlan) => {
  try {
    const token = getToken();

    const response = await axios.put(`https://apiadmin.drsunilkale.com/api/subscription-plans/${subscriptionPlanId}`, updatedPlan, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteSubscriptionPlan = async (subscriptionPlanId) => {
 try {
  const token = getToken();
    const response = await axios.delete(`https://apiadmin.drsunilkale.com/api/subscription-plans/${subscriptionPlanId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
