
// import React, { Component } from 'react';
// import './mcategory.css';
// import { FaEdit, FaSave, FaTrash } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
// import { fetchMappings, fetchCategories, updateMapping, deleteMapping } from '../services/categories';
// import { getToken } from '../services/token';
// import Navbar from '../components/Navbar';


// class VideoCategoryMapping extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       mappings: [],
//       categories: [],
//       searchTerm: '',
//       editMode: null,
//       editedMapping: null,
//       token: getToken(),
//     };
//   }

//   componentDidMount() {
//     this.fetchMappings();
//     this.fetchCategories();
//   }

//   fetchMappings = () => {
//     const { token } = this.state;
//     if (!token) {
//       console.error('Token is not available. Please authenticate.');
//       return;
//     }

//     fetchMappings(token)
//       .then((data) => {
//         this.setState({ mappings: data });
//       })
//       .catch((error) => {
//         console.error('Error fetching video-category mappings:', error);
//       });
//   };

//   fetchCategories = () => {
//     const { token } = this.state;
//     if (!token) {
//       console.error('Token is not available. Please authenticate.');
//       return;
//     }

//     fetchCategories(token)
//       .then((data) => {
//         this.setState({ categories: data });
//       })
//       .catch((error) => {
//         console.error('Error fetching categories:', error);
//       });
//   };

//   handleSearch = (event) => {
//     this.setState({ searchTerm: event.target.value });
//   };

//   handleEdit = (id) => {
//     const mappingToEdit = this.state.mappings.find((mapping) => mapping.id === id);
//     if (mappingToEdit) {
//       this.setState({
//         editMode: id,
//         editedMapping: { ...mappingToEdit },
//       });
//     }
//   };

//   handleSave = (id) => {
//     const { token, editedMapping } = this.state;
//     if (!token) {
//       console.error('Token is not available. Please authenticate.');
//       return;
//     }

//     updateMapping(token, id, {
//       category: {
//         categoryId: editedMapping.category.categoryId,
//       },
//       videoData: {
//         id: editedMapping.videoData.id,
//         title: editedMapping.videoData.title,
//       },
//     })
//       .then((editedMapping) => {
//         const updatedMappings = this.state.mappings.map((mapping) => {
//           if (mapping.id === editedMapping.id) {
//             return editedMapping;
//           }
//           return mapping;
//         });
//         this.setState({
//           mappings: updatedMappings,
//           editMode: null,
//           editedMapping: null,
//         });
//       })
//       .catch((error) => {
//         console.error('Error editing VideoCategoryMapping:', error);
//       });
//   };

//   handleDelete = (id) => {
//     const { token } = this.state;
//     if (!token) {
//       console.error('Token is not available. Please authenticate.');
//       return;
//     }

//     deleteMapping(token, id)
//       .then(() => {
//         this.setState((prevState) => ({
//           mappings: prevState.mappings.filter((mapping) => mapping.id !== id),
//         }));
//       })
//       .catch((error) => {
//         console.error('Error deleting VideoCategoryMapping:', error);
//       });
//   };

// render() {
//   const { mappings, searchTerm, editMode, categories } = this.state;
//   const filteredMappings = mappings.filter((mapping) => {
// return (
//       mapping.videoData.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       mapping.category.category.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//   });
//   return (
//     <>
//    <Navbar />
  
//     <div className="container">
//       <input
//         type="text"
//         className='aaa'
//         placeholder="Search"
//         value={searchTerm}
//         onChange={this.handleSearch}
//       />
//       <table className='tabeeles'>
//         <thead>
//           <tr>
//             <th className='tahesding'>ID</th>
//             <th className='tahesding'>Video Title</th>
//             <th className='tahesding'>Category</th>
//             <th className='tahesding'>Edit</th>
//             <th className='tahesding'>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredMappings.map((mapping) => (
//             <tr key={mapping.id} className='tablerowss'>
//               <td>{mapping.id}</td>
//               <td>
//                 {editMode === mapping.id ? (
//                   <input
//                     type="text"           
//                     value={this.state.editedMapping?.videoData.title || ''}
//                     onChange={(e) => {
//                       this.setState((prevState) => ({
//                         editedMapping: {
//                           ...prevState.editedMapping,
//                           videoData: {
//                             ...prevState.editedMapping?.videoData,
//                             title: e.target.value,
//                           },
//                         },
//                       }));
//                     }}
//                   />
//                 ) : (
//                   mapping.videoData.title
//                 )}
//               </td>
//               <td>
//                 {editMode === mapping.id ? (
//                   <select
//                    className='selects'
//                     value={this.state.editedMapping?.category.categoryId || ''}
//                     onChange={(e) => {
//                       this.setState((prevState) => ({
//                         editedMapping: {
//                           ...prevState.editedMapping,
//                           category: {
//                             ...prevState.editedMapping?.category,
//                             categoryId: e.target.value,
//                           },
//                         },
//                       }));
//                     }}
//                   >
//                     {categories.map((category) => (
//                       <option key={category.categoryId} value={category.categoryId}>
//                         {category.category}
//                       </option>
//                     ))}
//                   </select>
//                 ) : (
//                   mapping.category.category
//                 )}
//               </td>
//               <td>
//                 {editMode === mapping.id ? (
                  
//                   <FaSave className="icon" onClick={() => this.handleSave(mapping.id)} />
//                 ) : (
                  
//                   <FaEdit className="icon" onClick={() => this.handleEdit(mapping.id)} />
//                 )}
//               </td>
//               <td>
                
//                 <FaTrash className="icon" onClick={() => this.handleDelete(mapping.id)} />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
 
//     </>
//   );
// }
// }
// export default VideoCategoryMapping;


import React from 'react';
import './VideoDetail.css';
import Progress from '../components/Progress'

const VideoDetail = () => {

return ( 
  <>
  <Progress />
  </>
);
};

export default VideoDetail;