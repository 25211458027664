import React, { useEffect, useState } from "react";
import "./albumrow.css";
import Switch from "react-switch";
import DeleteAudio from "../AlbumDetails/DeleteAudio";
import { GenrateAudioUrl, UpdateAudio } from "../../services/audioAlbum";
import { FaPlay } from "react-icons/fa";
import { MdOutlineAudiotrack } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const AudioRow = ({
  audioId,
  description,
  status,
  thumbnailUrl,
  title,
  showButton,
  audioPlay,
  audioCount,
  updateApiCallDetails,
  isAudioPlaying,
  audioObject,
  albumObject
}) => {
  const [audioStatus, setAudioStatus] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setAudioStatus(status === "Active");
    setIsPlaying(isAudioPlaying)
  }, [status,isAudioPlaying]);
  const shortTitle = title.substring(0, 20) + (title.length > 20 ? "..." : "");

  const shortDescription =
    description.substring(0, 20) + (description.length > 20 ? "..." : "");
  const handleAudioStatus = (id) => {
    const formData = new FormData();
    if (!audioStatus) {
      formData.append("status", "Active");
    } else {
      formData.append("status", "Deactive");
    }
    UpdateAudio(id, formData)
      .then((res) => {
        if (res?.status === 200) {
          setAudioStatus(!audioStatus);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const GenrateUrl = async (id, expirationMin = 200) => {
    try {
      const response = await GenrateAudioUrl(id, expirationMin);
      audioPlay(response.data,id);
      setIsPlaying(true);
    } catch (err) {
      console.error(err);
    }
  };
  const goToUpdateAudio = async(audio,albumId)=>{
    navigate('/uploadaudio',{ state: { audio: audio,albumId:albumId} });
  }
  return (
    <div className="albumrow">
      
      <div className="album-thumbnail">
        {thumbnailUrl === "Thumbnail" ? (
          <b>Thumbnail</b>
        ) : (
          <img src={thumbnailUrl} alt="album thumbnail" />
        )}
      </div>
      <div className="title">
        <b>{shortTitle}</b>
      </div>

      <div className="description">
        <b>{shortDescription}</b>
      </div>

      <div className="edit">
        {showButton === "No" ? (
          <b>Count</b>
        ) : (
          audioCount
        )}
      </div>
      <div className="edit">
        {showButton === "No" ? (
          <b>Play</b>
        ) :  isPlaying ? (
          <MdOutlineAudiotrack size="1.5rem" />
        ) : (
          <FaPlay size="1.5rem" onClick={() => GenrateUrl(audioId, 300)} />
        )}
      </div>
      <div className="status">
        {status === "status" ? (
          <b>Status</b>
        ) : (
          <Switch
            onChange={() => handleAudioStatus(audioId)}
            checked={audioStatus}
          />
        )}
      </div>
      <div className="edit">
        {showButton === "No" ? (
          <b>Edit</b>
        ) : (
         
          <MdOutlineModeEditOutline size="1.5rem" onClick={()=>goToUpdateAudio(audioObject,albumObject)}/>
        )}
      </div>
      <div className="delete">
        {showButton === "No" ? (
          <b>Delete</b>
        ) : (
          <DeleteAudio audioId={audioId} />
        )}
      </div>
    </div>
  );
};

export default AudioRow;
