import React, { useState, useEffect } from 'react';
import './videoplan.css'; 
import Navbar from '../components/CustomNavbar';
import { createSubscriptionPlan, fetchSubscriptionPlans, updateSubscriptionPlan, deleteSubscriptionPlan } from '../services/apiCallServices';
import { ToastContainer, toast } from 'react-toastify';
import ButtonGroup from "@mui/material/ButtonGroup";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

const Manage = () => {
  const [planName, setPlanName] = useState('');
  const [planDetails, setPlanDetails] = useState('');
  const [price, setPrice] = useState('');
  const [durationType, setDurationType] = useState('');
  const [duration, setDuration] = useState('');
  const [plans, setPlans] = useState([]);
  const [isNewPlanOpen, setIsNewPlanOpen] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editingPlanId, setEditingPlanId] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansData = await fetchSubscriptionPlans();
        setPlans(plansData);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, []);

  const handleCreatePlan = async () => {
    const newPlan = {
      subscriptionPlanName: planName,
      subscriptionPlanDetails: planDetails,
      price: parseFloat(price),
      durationType: durationType, 
      duration: parseInt(duration)
    };

    try {
      const response = await createSubscriptionPlan(newPlan);
      console.log('New plan created:', response);
      toast.success('Plan created successfully');
      const updatedPlansData = await fetchSubscriptionPlans();
      setPlans(updatedPlansData);
      resetFields();
    } catch (error) {
      console.error('Error creating new plan:', error.message);
    }
  };


  const handleEditPlan = (index, subscriptionPlanId) => {
    setEditableRowIndex(index);
    setEditingPlanId(subscriptionPlanId);
    const { subscriptionPlanName, subscriptionPlanDetails, price, durationType, duration } = plans[index];
    setPlanName(subscriptionPlanName);
    setPlanDetails(subscriptionPlanDetails);
    setPrice(price.toString());
    setDurationType(durationType);
    setDuration(duration.toString());
  };

  const handleCancelEdit = () => {
    setEditableRowIndex(null);
    resetFields();
  };

  const handleSaveEdit = async () => {
    try {
      const updatedPlan = {
        subscriptionPlanName: planName,
        subscriptionPlanDetails: planDetails,
        price: parseFloat(price),
        durationType: durationType, 
        duration: parseInt(duration)
      };
      await updateSubscriptionPlan(editingPlanId, updatedPlan);
      toast.success('Plan updated successfully');
      const updatedPlansData = await fetchSubscriptionPlans();
      setPlans(updatedPlansData);
      resetFields();
    } catch (error) {
      console.error('Error updating plan:', error);
    }
  };

  const resetFields = () => {
    setPlanName('');
    setPlanDetails('');
    setPrice('');
    setDurationType('');
    setDuration('');
    setIsNewPlanOpen(false);
    setEditableRowIndex(null);
    setEditingPlanId(null);
  };

  const handleDeletePlan = async (index, subscriptionPlanId) => {
    try {
      await deleteSubscriptionPlan(subscriptionPlanId);
      const updatedPlans = plans.filter(plan => plan.subscriptionPlanId !== subscriptionPlanId);
      setPlans(updatedPlans);
      toast.success('Plan deleted successfully');
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  };

  return (
    <div className="video-plan-container">
      <Navbar />
      <div className="subscription-btn">
        <h3>Subscription Plan</h3>
        {!isNewPlanOpen && (
          <ButtonGroup variant="outlined" aria-label="Basic button group"><Button variant="contained" onClick={() => setIsNewPlanOpen(true)}>Create New</Button></ButtonGroup>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table className="custom-table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Plan Name</TableCell>
              <TableCell>Plan Details</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Duration Type</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>{isNewPlanOpen ? 'Save' : 'Edit'}</TableCell>
              <TableCell>{isNewPlanOpen ? 'Cancel' : 'Delete'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isNewPlanOpen && (
              <TableRow>
                <TableCell><TextField type="text" value={planName} onChange={(e) => setPlanName(e.target.value)} /></TableCell>
                <TableCell><TextField type="text" value={planDetails} onChange={(e) => setPlanDetails(e.target.value)} /></TableCell>
                <TableCell><TextField type="number" value={price} onChange={(e) => setPrice(e.target.value)} /></TableCell>
                <TableCell>
                  <Select value={durationType} onChange={(e) => setDurationType(e.target.value)}>
                    <MenuItem value="MINUTE">MINUTE</MenuItem>
                    <MenuItem value="LIFETIME">DAY</MenuItem>
                    <MenuItem value="MINUTE">MONTH</MenuItem>
                    <MenuItem value="YEAR">YEAR</MenuItem>
                    <MenuItem value="LIFETIME">LIFETIME</MenuItem>
                  </Select>
                </TableCell>
                <TableCell><TextField type="number" value={duration} onChange={(e) => setDuration(e.target.value)} /></TableCell>
                <TableCell>
                  <Button onClick={handleCreatePlan} variant="contained" color="primary">Submit</Button>
                </TableCell>
                <TableCell>
                  <Button onClick={handleCancelEdit} variant="contained" color="error">Cancel</Button>
                </TableCell>
              </TableRow>
            )}
            {plans.map((plan, index) => (
              <TableRow key={index}>
                <TableCell >{editableRowIndex === index ? <TextField type="text" value={planName} onChange={(e) => setPlanName(e.target.value)} /> : plan.subscriptionPlanName}</TableCell>
                <TableCell>{editableRowIndex === index ? <TextField type="text" value={planDetails} onChange={(e) => setPlanDetails(e.target.value)} /> : plan.subscriptionPlanDetails}</TableCell>
                <TableCell>{editableRowIndex === index ? <TextField type="number" value={price} onChange={(e) => setPrice(e.target.value)} /> : plan.price}</TableCell>
                <TableCell>
                  {editableRowIndex === index ? (
                    <Select value={durationType} onChange={(e) => setDurationType(e.target.value)}>
                      <MenuItem value="YEAR">YEAR</MenuItem>
                      <MenuItem value="MINUTE">MINUTE</MenuItem>
                      <MenuItem value="LIFETIME">LIFETIME</MenuItem>
                    </Select>
                  ) : (
                    plan.durationType
                  )}
                </TableCell>
                <TableCell>{editableRowIndex === index ? <TextField type="number" value={duration} onChange={(e) => setDuration(e.target.value)} /> : plan.duration}</TableCell>
                <TableCell>
                  {editableRowIndex === index ? <Button onClick={handleSaveEdit} variant="contained" color="primary">Save</Button> : <MdOutlineModeEditOutline size="1.5rem" onClick={() => handleEditPlan(index, plan.subscriptionPlanId)} className="edit-icon" />}
                </TableCell>
                <TableCell>
                  {editableRowIndex === index ? <Button onClick={handleCancelEdit} variant="contained" color="error">Cancel</Button> : <AiFillDelete size="1.5rem" onClick={() => handleDeletePlan(index, plan.subscriptionPlanId)} className="delete-icon" />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer />
    </div>
  );
};
export default Manage;
