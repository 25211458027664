import React, { useState, useEffect, useCallback } from 'react';
import './Transaction.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getAllTransactions } from '../services/apiCallServices';
import { getToken } from '../services/token';
import Navbar from '../components/CustomNavbar';
import { FaArrowCircleUp } from "react-icons/fa";
import { FaRupeeSign } from 'react-icons/fa'

const Transaction = () => {
  const [fromDate, setFromDate] = useState(null);
  const [allTransactionData,setAllTransactionData] = useState([])
  const [toDate, setToDate] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');

  const handleSearch = useCallback(async () => {
    try {
      setLoading(true);
      const token = getToken();
      let url = 'https://apiadmin.drsunilkale.com/api/transactions/date-wise';
  
      if (fromDate && toDate) {
      
        toDate.setHours(23, 59, 59);
        url = `https://apiadmin.drsunilkale.com/api/transactions/date-wise?fromDate=${fromDate.toISOString().split('T')[0]}&toDate=${toDate.toISOString().split('T')[0]}`;
      }
  
      const transactions = await getAllTransactions(token, url);
     
      let filteredTransactions = transactions.filter(transaction => {
        const transactionDate = new Date(transaction.transactionDate);
  
        return (fromDate && toDate)
          ? transactionDate >= fromDate && transactionDate <= toDate
          : true; 
      });
      setAllTransactionData(filteredTransactions)
      if (statusFilter) {
        filteredTransactions = filteredTransactions.filter(transaction => 
          transaction.transactionStatus === statusFilter
        );
      }
      filteredTransactions.sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate));
  
      setFilteredTransactions(filteredTransactions);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setLoading(false);
    }
  },[fromDate, toDate,statusFilter]);
  
  useEffect(() => {
    handleSearch();
  }, [handleSearch]);
  const clearFilter = () => {
    setFromDate(null);
    setToDate(null);
    setStatusFilter('');

    setFilteredTransactions(allTransactionData);
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="transaction-container">
     <Navbar />
      <div className="search-container">
        <div className="search-field">
          <label htmlFor="startDate">From Date:</label>
          <DatePicker
            id="fromDate"
            selected={fromDate}
            onChange={date => setFromDate(date)}
            selectsStart
            startDate={fromDate}
            endDate={toDate}
            placeholderText="From Date"
          />
        </div>
        
        <div className="search-field">
          <label htmlFor="toDate">To Date:</label>
          <DatePicker
            id="toDate"
            selected={toDate}
            onChange={date => setToDate(date)}
            selectsEnd
            startDate={fromDate}
            endDate={toDate}
            placeholderText="To Date"
            maxDate={new Date()}
          />
        </div>
        {/* <button className="search-button" onClick={handleSearch}>Search</button> */}
        <div className="search-field">
          <label htmlFor="status">Status:</label>
          <select 
            id="status"
            value={statusFilter}
            onChange={e => setStatusFilter(e.target.value)}
            className="status-dropdown"
          >
            <option value="">All</option>
            <option value="Created">Created</option>
            <option value="Paid">Paid</option>
            <option value="Failure">Failure</option>
            <option value="An_error_occurred.">An_error_occurred</option>
            <option value="Aborted">Aborted</option>
          </select>
        </div>
        <button className="search-button" onClick={clearFilter}>Clear Filter</button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Transaction ID</th>
              <th>Order Id</th>
              <th>Details</th>
              <th>Status</th>
              <th>Transaction Date</th>
              <th>Amount <FaRupeeSign className='rupess-icon' /></th>
              {/* <th>Subscription ID</th> */}
              {/* <th>Video Title</th> */}
              <th>User Email</th>
              <th>User Name</th>
              <th>Contact No</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction,i) => (
              <tr key={transaction.transactionId}>
                 <td>{i+1}</td>
                <td className='amount'>{transaction.transactionId}</td>
                <td>{transaction.orderId}</td>
                <td>{transaction.transactionDetails}</td>
                <td className={transaction.transactionStatus.toLowerCase()}>{transaction.transactionStatus}</td>
                <td>
  {new Date(transaction.transactionDate).toLocaleString('en-IN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, 
  })}
</td>
                <td className="amount-cell">{transaction.transactionAmount}</td>
                <td>{transaction.user ? transaction.user.email : '--'}</td>
                <td>{transaction.user ? transaction.user.user_name : '--'}</td>
                <td className='amount-cell'>{transaction.user ? transaction.user.contact : '--'}</td>
                
              
              </tr>
            ))}
          </tbody>
        </table>
      )}

<button className="scroll-to-top-count" onClick={handleScrollToTop}>
      <FaArrowCircleUp />
      </button>
    </div>
  );
};

export default Transaction;