import React from 'react';
 import "./Dashboard.css";
 import { BsFillCameraVideoFill } from "react-icons/bs";
 import { Link } from "react-router-dom";
 import { MdSubscriptions } from "react-icons/md";
 import { FaUserAlt } from "react-icons/fa";
 import { BsThreeDots } from "react-icons/bs";
 import CustomNavbar from './CustomNavbar';
 const Dashboard = () => {

   return ( 
     <div className="main-container">
      <CustomNavbar />
         <div className="App1">          
           <h1 className="heading">Control panel</h1>
           <div className="management-section video-management">
             <div className="video-circle">
               <BsFillCameraVideoFill className="font-icon" />
             </div>
             <div className="video-text">
              <h5>VIDEO MANAGEMENT </h5>
            </div>
            <Link to="/Videolist" className="text-link1">
              <p>VIDEO LIST</p>
            </Link>
            {/* <Link to="/Videosearch" className="text-link1">
              <p>MANAGE VIDEO BY ID/NAME</p>
            </Link> */}
            <Link to="/Createvideo" className="text-link1">
              <p>UPLOAD VIDEO</p>
            </Link>
          
          </div>

          <div className="management-section other-management">
            <div className="video-circle">
            <MdSubscriptions className="font-icon" />
            </div>
            <div className="video-text">
              <h5>AUDIO MANAGEMENT </h5>
            </div>
            <Link to="/albums" className="text-link1">
              <p>ALBUM LIST</p>
            </Link>
            <Link to="/upload-album" className="text-link1">
              <p>CREATE ALBUM</p>
            </Link>
           
            {/* <Link to="/Uploadaudio" className="text-link1" >
              <p>CREATE AUDIO</p>
            </Link> */}
          </div>
          <div className="management-section user-management">
            <div className="video-circle">
              <FaUserAlt className="font-icon" />
            </div>
            <div className="video-text">
              <h5>USER MANAGEMENT</h5>
            </div>
            {/* <Link to="/Viewsub" className="text-link1">
              <p>VIEW SUBSCRIPTION</p>
            </Link> */}
            <Link to="/Transaction" className="text-link1">
              <p>TRANSACTION DETAILS</p>
            </Link>
            <Link to="/userwisevideo" className="text-link1">
              <p>USER VIEW COUNT</p>
            </Link>
            <Link to="/all-user-list" className="text-link1">
              <p>ALL USERS</p>
            </Link>
          </div>
          <div className="management-section subscription-management">
            <div className="video-circle">
            <BsThreeDots className="font-icon" />
              
            </div>
            <div className="video-text">
              <h5> SUBSCRIPTION PLAN MANAGEMENT</h5>
            </div>
            <Link to="/Manage" className="text-link1">
              <p>CREATE AND MANAGE PLAN</p>
            </Link>
            <Link to="/user-subscription-list" className="text-link1">
              <p>USER SUBSCRIPTION LIST</p>
            </Link>
            <Link to="/user-video-album-list" className="text-link1">
              <p>USER VIDEO AUDIO BUY LIST</p>
            </Link>
          </div>

          
          
        </div>
        
        <div className="footer">
            <div className="name">Dr. Sunil S. Kale © All Rights Reserved</div>
          </div>
        </div>
         
    
  );
};

export default Dashboard;