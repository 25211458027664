import axios from "axios";
import { BASE_URL, privateAxios } from "./helper";
import { getToken } from "./token";
export const GetAllVideosApi = async (page = 0, size = 8, searchQuery = "") => {
    const response = await privateAxios.get(`/admin/video/search?page=${page}&size=${size}&searchQuery=${searchQuery}`);
    return response;
}
export const DeleteVideoApi = async (id) => {
    const response = await privateAxios.delete(`/admin/video/delete/${id}`);
    return response;
}
export const UpdateVideoApi = async (id,formData,onUploadProgress) =>{
        return axios.put(`${BASE_URL}/admin/video/update/${id}`, formData, {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress
        });
}
export const UpdateVideoStatus = async (id,formData) =>{
        const response = await privateAxios.put(`/admin/video/update/${id}`,formData);
        return response;
}