import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AiFillDelete } from "react-icons/ai";
import { DeleteAudioApi } from "../../services/audioAlbum";
import { toast } from "react-toastify";
const DeleteAudio = ({ audioId, updateApiCallDetails }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (id) => {
    setLoading(true);
    DeleteAudioApi(id)
      .then((res) => {
        setLoading(false);
        setOpen(false);
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Audio Is Not Deleted");
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <AiFillDelete onClick={handleClickOpen} size="1.5rem" />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"You Really want to delete this Audio"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After clicking Agree you delete entire Audio
          </DialogContentText>
        </DialogContent>

        {loading ? (
          <DialogActions>
            <Button variant="contained">Deleting ....</Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={() => handleDelete(audioId)} autoFocus>
              Agree
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default DeleteAudio;
