import React, { useState } from 'react';
import './Viewsub.css';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import Navbar from '../components/CustomNavbar';

const ViewSubscription = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  return (
    <div className="video-list-container background-image">
     <Navbar />
    <div className="container">
      <div className="row">
        <div className="col-lg-12 main">
          <div className="main-box clearfix">
            <div className="table-responsive">
              <table className="table user-list">
                <thead>
                  <tr>
                    <th><span>User</span></th>
                    <th><span>Status</span></th>
                    <th><span>Email</span></th>
                    <th><span>Date</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="User Avatar" />
                      <p className="user-link">Mila Kunis</p>
                    </td>
                    <td className="text-center">
                      <span className="label label-default">Active</span>
                    </td>
                    <td>
                      <Link to="mailto:mila@kunis.com">mila@kunis.com</Link>
                    </td>
                    <td>
                      <div className="date-picker-container">
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => setFromDate(date)}
                          selectsStart
                          startDate={fromDate}
                          endDate={toDate}
                          className={classNames('date-picker', { 'custom-class': true })}
                          placeholderText="From"
                        />
                        <DatePicker
                          selected={toDate}
                          onChange={(date) => setToDate(date)}
                          selectsEnd
                          startDate={fromDate}
                          endDate={toDate}
                          minDate={fromDate}
                          className={classNames('date-picker', { 'custom-class': true })}
                          placeholderText="To"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="User Avatar" />
                      <p className="user-link">George Clooney</p>
                    </td>
                    <td className="text-center">
                      <span className="label label-default">Active</span>
                    </td>
                    <td>
                      <Link to="mailto:mila@kunis.com">mila@kunis.com</Link>
                    </td>
                    <td>
                      <div className="date-picker-container">
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => setFromDate(date)}
                          selectsStart
                          startDate={fromDate}
                          endDate={toDate}
                          className={classNames('date-picker', { 'custom-class': true })}
                          placeholderText="From"
                        />
                        <DatePicker
                          selected={toDate}
                          onChange={(date) => setToDate(date)}
                          selectsEnd
                          startDate={fromDate}
                          endDate={toDate}
                          minDate={fromDate}
                          className={classNames('date-picker', { 'custom-class': true })}
                          placeholderText="To"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="User Avatar" />
                      <p className="user-link">Ryan Gossling</p>
                    </td>

                    <td className="text-center">
                      <span className="label label-default">Active</span>
                    </td>
                    <td>
                      <Link to="mailto:mila@kunis.com">mila@kunis.com</Link>
                    </td>
                    <td>
                      <div className="date-picker-container">
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => setFromDate(date)}
                          selectsStart
                          startDate={fromDate}
                          endDate={toDate}
                          className={classNames('date-picker', { 'custom-class': true })}
                          placeholderText="From"
                        />
                        <DatePicker
                          selected={toDate}
                          onChange={(date) => setToDate(date)}
                          selectsEnd
                          startDate={fromDate}
                          endDate={toDate}
                          minDate={fromDate}
                          className={classNames('date-picker', { 'custom-class': true })}
                          placeholderText="To"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul className="pagination pull-right">
              <li><Link to="#"><i className="fa fa-chevron-left"></i></Link></li>
              <li><Link to="#">1</Link></li>
              <li><Link to="#">2</Link></li>
              <li><Link to="#">3</Link></li>
              <li><Link to="#">4</Link></li>
              <li><Link to="#">5</Link></li>
              <li><Link to="#"><i className="fa fa-chevron-right"></i></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ViewSubscription;